import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { notify } from "@/core/helpers/globalJaya";
declare let window: any;

export interface FirebaseToken {
  id: number | null;
  value: string | null;
}

export interface Notification {
  id: number;
  object_id: number;
  seen: boolean;
  created_at: string;
  text: string;
}

export interface Notifications {
  notifications: Array<Notification>;
  firebaseToken: FirebaseToken;
  notificationCount: number;
  notificationCountSent: number;
  offset: number;
  limit: number;
  seen_param: boolean;
}
@Module
export default class NotificationsModule
  extends VuexModule
  implements Notifications
{
  firebaseToken = {
    id: null,
    value: null,
  };
  notifications = [] as Array<Notification>;
  notificationCount = 0;
  offset = 0;
  limit = 10;
  seen_param = false;
  notificationCountSent = 0;
  get deviceId(): number | null {
    return this.firebaseToken.id;
  }
  get getNotificationCount(): number {
    return this.notificationCount;
  }
  get getNotificationCountSent(): number {
    return this.notificationCountSent;
  }
  get getNotifications(): Array<Notification> {
    return this.notifications;
  }
  @Mutation
  [Mutations.SET_FIREBASE_TOKEN_ID](payload) {
    this.firebaseToken.id = payload;
  }
  @Mutation
  [Mutations.SET_FIREBASE_TOKEN_VALUE](payload) {
    this.firebaseToken.value = payload;
  }
  @Action
  [Actions.REQUEST_FIREBASE_TOKEN]() {
    const cordova = window.cordova;
    const context = this.context;
    // request permission to send notifications
    cordova.plugins.firebase.messaging
      .requestPermission({ forceShow: true })
      .then(function () {
        cordova.plugins.firebase.messaging.getToken().then(function (token) {
          context.commit(Mutations.SET_FIREBASE_TOKEN_VALUE, token);
          if (
            context.getters.currentUser &&
            context.getters.deviceId === null
          ) {
            context.dispatch(Actions.FETCH_AND_MANAGE_DEVICES, {});
          }
        });
      });
    cordova.plugins.firebase.messaging.onMessage(function (payload) {
      payload.firebase = "Firebase";
      context.dispatch(Actions.FETCH_LATEST_NOTIFICATION, {});
      if (cordova.platformId === "android") {
        notify({
          duration: 4000,
          text: payload.gcm.body,
          color: "success",
        });
      } else {
        notify({
          duration: 4000,
          text: payload.alert.aps.body,
          color: "success",
        });
      }
    });
    cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
      console.log("New background FCM message: ", payload);
    });
  }

  @Action
  [Actions.FETCH_AND_MANAGE_DEVICES](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("firebase_devices")
        .then((response) => {
          // A token has been retrieved, need to know if the backend is already aware of it
          if (this.firebaseToken.value && this.firebaseToken.id === null) {
            const tokens = response.data.filter((e) => {
              return e.token === this.firebaseToken.value;
            });
            if (tokens.length > 0) {
              this.context.commit(
                Mutations.SET_FIREBASE_TOKEN_ID,
                tokens[0].id
              );
              resolve(response);
            } else {
              payload.token = this.firebaseToken.value;
              if (window.cordova && window.cordova.platformId === "ios") {
                payload.device_type = 2;
              }
              if (window.cordova && window.cordova.platformId === "android") {
                payload.device_type = 1;
              }
              ApiService.post("firebase_devices/", payload).then(
                (responseToken) => {
                  this.context.commit(
                    Mutations.SET_FIREBASE_TOKEN_ID,
                    responseToken.data.id
                  );
                  this.context.commit(
                    Mutations.SET_FIREBASE_TOKEN_VALUE,
                    responseToken.data.token
                  );
                  resolve(response);
                }
              );
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  @Action
  [Actions.UPDATE_DEVICE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch("firebase_devices/" + payload.id + "/", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de mettre à jour les appareils firebase";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Action({ rawError: true })
  [Actions.FETCH_NOTIFICATIONS](params) {
    return new Promise((resolve, reject) => {
      if (!("limit" in params)) {
        params.limit = this.limit;
      }
      if (!("seen" in params)) {
        params.seen = this.seen_param;
      }
      if (!("offset" in params)) {
        params.offset = this.offset;
      }
      ApiService.query("notifications", { params: params })
        .then((response) => {
          if (!params.seen) {
            this.context.commit(
              Mutations.SET_NOTIFICATION_COUNT,
              response.data.count
            );
          }
          this.context.commit(
            Mutations.APPEND_NOTIFICATIONS_END,
            response.data.results
          );
          const notifDelta =
            params.limit -
            response.data.results.filter((el) => {
              return !el.seen;
            }).length;
          if (params.seen) {
            this.context.commit(
              Mutations.UPDATE_OFFSET,
              params.limit + this.offset
            );
          }
          if (notifDelta !== 0 && !params.seen) {
            this.context.commit(Mutations.UPDATE_SEEN_PARAM, true);
            this.context.commit(
              Mutations.UPDATE_OFFSET,
              this.notifications.length
            );
          }
          // Limit not reached on unseen, fetch the missing part
          if (0 < notifDelta && notifDelta <= params.limit && !params.seen) {
            this.context.dispatch(Actions.FETCH_NOTIFICATIONS, {
              limit: notifDelta,
            });
          }

          resolve(response.data.results);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer les notifications";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Action({ rawError: true })
  [Actions.FETCH_LATEST_NOTIFICATION]() {
    return new Promise((resolve, reject) => {
      const params = {
        limit: 1,
        seen: false,
        offset: 0,
      };
      ApiService.query("notifications", { params: params }).then((response) => {
        if (response.data.count) {
          this.context.commit(
            Mutations.SET_NOTIFICATION_COUNT,
            this.context.getters.getNotificationCount + 1
          );
        }
        this.context.commit(
          Mutations.APPEND_NOTIFICATIONS_START,
          response.data.results
        );
        resolve(response.data.results);
      });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_NOTIFICATION](params) {
    return new Promise((resolve, reject) => {
      ApiService.patch("notifications/" + params.id + "/", params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de mettre à jour les notifications";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Mutation
  [Mutations.SET_NOTIFICATION_COUNT](payload) {
    this.notificationCount = payload > 0 ? payload : 0;
  }
  @Mutation
  [Mutations.SET_NOTIFICATION_COUNT_SENT](payload) {
    this.notificationCountSent = payload > 0 ? payload : 0;
  }
  @Mutation
  [Mutations.APPEND_NOTIFICATIONS_END](payload) {
    payload.forEach((el) => {
      this.notifications.push(el as Notification);
    });
  }
  @Mutation
  [Mutations.APPEND_NOTIFICATIONS_START](payload) {
    payload.forEach((el) => {
      this.notifications.unshift(el as Notification);
    });
  }
  @Mutation
  [Mutations.UPDATE_OFFSET](value) {
    this.offset = value;
  }
  @Mutation
  [Mutations.UPDATE_LIMIT](value) {
    this.limit = value;
  }
  @Mutation
  [Mutations.UPDATE_SEEN_PARAM](value) {
    this.seen_param = value;
  }
}
