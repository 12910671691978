import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { notify } from "@/core/helpers/globalJaya";
import store from "@/store";
import { CookieComponent } from "@/assets/ts/components";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.timeout = 5000;
    ApiService.vueInstance.axios.defaults.withCredentials = true;
    ApiService.vueInstance.axios.defaults.xsrfCookieName = "csrftoken";
    ApiService.vueInstance.axios.defaults.xsrfHeaderName = "X-CSRFToken";

    ApiService.vueInstance.axios.interceptors.request.use((axiosConfig) => {
      // Todo: no token must be set after log in --> Use same as Hortilio--> à tester
      if (process.env.VUE_APP_USE_TOKEN) {
        const authToken = localStorage.getItem("jayauthToken");
        if (authToken) {
          axiosConfig.headers["Authorization"] = "Token " + authToken;
        }
      }
      if (!store.getters.isOnline) {
        notify({
          text: "Vous semblez hors-ligne, cette action pourrait ne pas aboutir",
          color: "warning",
          duration: 3000,
        });
      }
      return axiosConfig;
    });

    ApiService.vueInstance.axios.interceptors.response.use(
      (axiosConfig) => {
        return axiosConfig;
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          notify({
            text:
              "Le délai d'attente de " +
              ApiService.vueInstance.axios.defaults.timeout +
              ` ms a été dépassé pour l'url ${error.config.url}`,
            color: "error",
            duration: 3000,
          });
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static postWithTimeout(
    resource: string,
    params: any,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static patch(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
  public static getApiHeaders(): any {
    const headers = {};

    // Add CSRF Token
    headers["X-CSRFToken"] = CookieComponent.get("csrftoken");
    // Token Access for mobile app
    if (process.env.VUE_APP_USE_TOKEN) {
      const authToken = localStorage.getItem("jayauthToken");
      if (authToken) {
        headers["Authorization"] = "Token " + authToken;
      }
    }

    return headers;
  }
}
export default ApiService;
