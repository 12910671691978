import ApiService from "@/core/services/ApiService";
import CacheService, {
  offlineObjectsFK,
  offlineObjectsRelatedName,
} from "@/core/services/CacheService";
import {
  Exploitation,
  ExploitationVersion,
} from "@/store/modules/ExploitationsModule";
import {
  Diagnostic,
  DiagnosticResult,
  Factor,
} from "@/store/modules/DiagnosticsModule";
import { notify } from "@/core/helpers/globalJaya";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store/";
import router from "@/router/router.ts";

export type OfflineObjectType =
  | Exploitation
  | ExploitationVersion
  | Diagnostic
  | Factor
  | DiagnosticResult;

const apiRelatedName = {
  results: "diagnostic_results/",
  diagnostics: "diagnostics/",
  exploitations: "exploitations/",
  exploitation_versions: "exploitation_versions/",
};

class OfflineService {
  public static fetchObject(objectKey: string): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve) => {
      resolve(CacheService.fetchCacheObject(objectKey));
    });
  }

  public static addObject(objectKey: string, objectValue: any) {
    return new Promise<OfflineObjectType>((resolve, reject) => {
      if (store.getters.isOnline) {
        if (objectValue.id) {
          delete objectValue.id;
        }
        ApiService.post(apiRelatedName[objectKey], objectValue)
          .then((response) => {
            CacheService.addCacheObject(objectKey, response.data, false, true)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        objectValue.id = CacheService.getNewCacheId(objectKey);
        CacheService.addCacheObject(objectKey, objectValue, true, true)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  public static updateObject(objectKey: string, objectValue: any) {
    return new Promise<OfflineObjectType>((resolve, reject) => {
      if (store.getters.isOnline) {
        ApiService.patch(
          apiRelatedName[objectKey] + objectValue.id + "/",
          objectValue
        )
          .then((response) => {
            CacheService.updateCacheObject(objectKey, response.data, false)
              .then(() => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        CacheService.updateCacheObject(objectKey, objectValue, true, "toUpdate")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  public static deleteObject(objectKey: string, objectValue: any) {
    return new Promise<any>((resolve, reject) => {
      if (store.getters.isOnline) {
        ApiService.delete(apiRelatedName[objectKey] + objectValue.id + "/")
          .then(() => {
            CacheService.deleteCacheObject(
              objectKey,
              objectValue.id,
              false,
              true
            )
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        CacheService.deleteCacheObject(objectKey, objectValue.id, true, true)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  public static async syncCache() {
    if (store.getters.isOnline) {
      const offlineObjects = [
        "exploitations",
        "diagnostics",
        "quiz",
        "otex",
        "ecosystemic_services_categories",
        "factor_groups",
        "module_videos",
      ];

      const unsyncedObjectsToAdd =
        CacheService.fetchUnsyncedCacheObjects("toAdd");
      interface ObjectToUpdateType {
        type: string;
        modifyingKey: string;
        oldParentId: number;
        newParentId: number;
      }
      const objectsWithIdsToUpdate: Array<ObjectToUpdateType> = [];
      //for each localObject with add SyncIssue do add API Call
      for (const key of Object.keys(unsyncedObjectsToAdd)) {
        await Promise.all(
          unsyncedObjectsToAdd[key].map(async (object) => {
            // check if object needs an id update from previously synced object
            const objectsTypeWithIdToUpdate = objectsWithIdsToUpdate.filter(
              (item) => item.type === key
            );
            if (objectsTypeWithIdToUpdate.length > 0) {
              const objectWithIdToUpdate = objectsTypeWithIdToUpdate.find(
                (itm) =>
                  object.objectValue[offlineObjectsFK[key]] === itm.oldParentId
              );
              if (objectWithIdToUpdate) {
                object.objectValue[objectWithIdToUpdate.modifyingKey] =
                  objectWithIdToUpdate.newParentId;
              }
            }

            // remove corresponding Cache object
            await CacheService.deleteCacheObject(
              object.key,
              object.objectValue.id,
              false,
              false
            );

            await this.addObject(object.key, object.objectValue).then(
              (response) => {
                // add unsyncedObjects children to objectsTypeWithIdToUpdate array
                let possibleChildren: Array<string> = [];
                if (offlineObjectsRelatedName[object.key]) {
                  possibleChildren = possibleChildren.concat(
                    offlineObjectsRelatedName[object.key]
                  );
                }
                if (object.key === "exploitation_versions") {
                  possibleChildren.push("diagnostics");
                }
                if (possibleChildren && possibleChildren.length > 0) {
                  for (const childKey of possibleChildren) {
                    const children = unsyncedObjectsToAdd[childKey].filter(
                      (childObject) => {
                        const id =
                          childObject.objectValue[offlineObjectsFK[childKey]];
                        return id == object.id;
                      }
                    );
                    for (const child of children) {
                      const newObject: ObjectToUpdateType = {
                        type: childKey,
                        modifyingKey: offlineObjectsFK[childKey],
                        oldParentId:
                          child.objectValue[offlineObjectsFK[childKey]],
                        newParentId: Number(response.id),
                      };
                      objectsWithIdsToUpdate.push(newObject);
                    }
                  }
                }
              }
            );
          })
        );
      }

      //get all backend Data
      await Promise.all(
        //for each data if id is not in local storage, add it
        offlineObjects.map(async (offlineObject) => {
          const response = await ApiService.get(offlineObject);
          const backEndData = response.data;
          for (const obj of backEndData) {
            await CacheService.addCacheObject(offlineObject, obj, false, true);
          }

          // if frontend has synced data that backend doesn't : delete that data
          const allCacheObjects = await CacheService.fetchCacheObject(
            offlineObject
          );
          const backEndDeletedObjects = allCacheObjects.filter(
            (cacheObject) => {
              let bool = !backEndData.some(
                (backObject) => backObject.id === cacheObject.id
              );
              //dont delete objects with toAdd in case of errors
              bool =
                bool &&
                unsyncedObjectsToAdd[offlineObject] &&
                !unsyncedObjectsToAdd[offlineObject].some(
                  (item) => item.objectValue.id === cacheObject.id
                );
              return bool;
            }
          );

          for (const backEndDeletedObject of backEndDeletedObjects) {
            await CacheService.deleteCacheObject(
              offlineObject,
              backEndDeletedObject.id,
              false,
              true
            );
          }
        })
      );

      // delete and update objects AFTER backend Data sync, to avoid making API calls to unexisting objects
      const unsyncedObjectsToDelete =
        CacheService.fetchUnsyncedCacheObjects("toDelete");
      for (const key of Object.keys(unsyncedObjectsToDelete).reverse()) {
        await Promise.all(
          unsyncedObjectsToDelete[key].map(async (object) => {
            await this.deleteObject(object.key, object.objectValue);
          })
        );
      }
      const unsyncedObjectsToUpdate =
        CacheService.fetchUnsyncedCacheObjects("toUpdate");
      for (const key of Object.keys(unsyncedObjectsToUpdate)) {
        await Promise.all(
          unsyncedObjectsToUpdate[key].map(async (object) => {
            await this.updateObject(object.key, object.objectValue);
          })
        );
      }

      notify({
        text: "Synchronisation des données terminée.",
        color: "success",
        duration: 3000,
      });
    }

    //sync store
    // TODO put all this in a Promise.all()
    try {
      await store.dispatch(Actions.FETCH_DIAGNOSTICS);
      try {
        await store.dispatch(Actions.FETCH_EXPLOITATIONS);
        try {
          await store.dispatch(Actions.FETCH_QUIZ);
          try {
            await store.dispatch(Actions.FETCH_OTEX);
          } catch {
            router.push({ name: "homepage" });
            notify({
              text: "Une erreur est survenue à la récupération des questionnaires. Veuillez réessayer ultérieurement",
              color: "error",
            });
          }
        } catch {
          router.push({ name: "homepage" });
          notify({
            text: "Une erreur est survenue à la récupération des questionnaires. Veuillez réessayer ultérieurement",
            color: "error",
          });
        }
      } catch {
        router.push({ name: "homepage" });
        notify({
          text: "Une erreur est survenue à la récupération des exploitations. Veuillez réessayer ultérieurement",
          color: "error",
        });
      }
    } catch {
      router.push({ name: "homepage" });
      notify({
        text: "Une erreur est survenue à la récupération des diagnostics. Veuillez réessayer ultérieurement",
        color: "error",
      });
    }
  }
}

export default OfflineService;
