import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ExploitationsModule from "@/store/modules/ExploitationsModule";
import DiagnosticsModule from "@/store/modules/DiagnosticsModule";
import BackOfficeFounderModule from "@/store/modules/FounderModule";
import GdprModule from "@/store/modules/GdprModule";
import NotificationsModule from "@/store/modules/NotificationsModule";
import VideosModule from "@/store/modules/VideosModule";
import PrescriberModule from "@/store/modules/PrescriberModule";
import VersioningModule from "@/store/modules/VersioningModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ExploitationsModule,
    DiagnosticsModule,
    BackOfficeFounderModule,
    GdprModule,
    NotificationsModule,
    VideosModule,
    PrescriberModule,
    VersioningModule,
  },
});

export default store;
