import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions } from "@/store/enums/StoreEnums";
import { notify } from "@/core/helpers/globalJaya";
import ApiService from "@/core/services/ApiService";

export interface Founder {
  id: number;
}

@Module
export default class FounderModule extends VuexModule implements Founder {
  id = 1;

  @Action
  [Actions.GET_DIAGNOSTIC_STATS_FOUNDERS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query("/diagnostic_stats_founders/", { params: payload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer les statistiques AgriBEST";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_DIAGNOSTIC_STATS_USER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query("/diagnostic_stats_users/", { params: payload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer les statistiques AgriBEST";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.SEND_MAIL_DB_DOWNLOAD_CSV](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/mail_db_as_csv/", { params: payload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible d'envoyer le mail pour télécharger des données AgriBEST";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_LOCATIONS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query("/ref_geo/" + payload.type + "/", { params: payload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de récupérer les informations géographiques";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_GENERAL_STATS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query("/general_stats", { params: payload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de récupérer les statistiques générales AgriBEST";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }
}
