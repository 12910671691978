import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { notify } from "@/core/helpers/globalJaya";

export interface minimumVersion {
  minimum_version: string;
}

export default class VersioningModule
  extends VuexModule
  implements minimumVersion
{
  minimum_version = "";

  @Action
  [Actions.FETCH_MINIMUM_VERSION]() {
    return new Promise((resolve, reject) => {
      ApiService.get("minimum_version")
        .then((response) => {
          this.minimum_version = response.data[0]?.minimum_version;
          resolve(this.minimum_version);
        })
        .catch(() => {
          const err_msg =
            "Impossible de récupérer le numéro de version minimale nécessaire";
          notify({
            text: err_msg,
            color: "error",
          });
        });
    });
  }
}
