import { deepCopy } from "@/core/helpers/globalJaya";
import { OfflineObjectType } from "@/core/services/OfflineService";

export const offlineObjectsRelatedName = {
  exploitations: ["exploitation_versions"],
  diagnostics: ["results"],
  quiz: ["factors"],
  factors: ["levels"],
};

export const offlineObjectsFK = {
  levels: "factors",
  factors: "quiz",
  results: "diagnostic",
  exploitation_versions: "exploitation",
  diagnostics: "exploitation",
};

export const offlineCascadeDeletion = {
  exploitations: ["exploitation_versions"],
  exploitation_versions: ["diagnostics"],
  diagnostics: ["results"],
};

const offlineSupplementaryFields = {
  results: [{ name: "level_details", key: "levels", id: "level" }],
};

class CacheService {
  public static fetchCacheObject(objectKey: string): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve) => {
      resolve(LocalStorageService.fetchLocalObjects(objectKey));
    });
  }

  public static fetchUnsyncedCacheObjects(syncType: string): Array<any> {
    return LocalStorageService.fetchLocalUnsyncedObject(syncType);
  }

  public static getNewCacheId(objectKey: string): number {
    return LocalStorageService.getNewLocalId(objectKey);
  }

  public static addCacheObject(
    objectKey: string,
    objectValue: any,
    offline: boolean,
    recursive: boolean
  ): Promise<OfflineObjectType> {
    return new Promise<OfflineObjectType>((resolve) => {
      if (!objectValue.id) {
        objectValue.id = this.getNewCacheId(objectKey);
      }

      // if parent add parent ID
      const parentId: number = offlineObjectsFK[objectKey]
        ? objectValue[offlineObjectsFK[objectKey]]
        : null;

      // add supplementary fields
      if (offlineSupplementaryFields[objectKey]) {
        Promise.all(
          offlineSupplementaryFields[objectKey].map((field) => {
            LocalStorageService.fetchLocalObject(
              field.key,
              objectValue[field.id]
            ).then((objToAdd) => {
              objectValue[field.name] = objToAdd;
            });
          })
        ).then(() => {
          LocalStorageService.addLocalObject(
            objectKey,
            objectValue,
            offline,
            parentId,
            recursive
          ).then((response) => {
            resolve(response);
          });
        });
      } else {
        LocalStorageService.addLocalObject(
          objectKey,
          objectValue,
          offline,
          parentId,
          recursive
        ).then((response) => {
          resolve(response);
        });
      }
    });
  }

  public static updateCacheObject(
    objectKey: string,
    objectValue: any,
    offline: boolean,
    synchroStatus = "ok"
  ): Promise<OfflineObjectType> {
    return new Promise<OfflineObjectType>((resolve, reject) => {
      LocalStorageService.fetchLocalObject(objectKey, objectValue.id)
        .then((response) => {
          // Get complete object
          const editingObject = response;
          for (const key of Object.keys(objectValue)) {
            editingObject[key] = objectValue[key];
          }
          // add supplementary fields
          if (offlineSupplementaryFields[objectKey]) {
            Promise.all(
              offlineSupplementaryFields[objectKey].map((field) => {
                LocalStorageService.fetchLocalObject(
                  field.key,
                  editingObject[field.id]
                ).then((objToAdd) => {
                  editingObject[field.name] = objToAdd;
                });
              })
              //TODO manage parent id, for instance what happens if it changes ?
            ).then(() => {
              LocalStorageService.updateLocalObject(
                objectKey,
                editingObject,
                offline,
                synchroStatus
              ).then((response) => {
                resolve(response);
              });
            });
          } else {
            LocalStorageService.updateLocalObject(
              objectKey,
              editingObject,
              offline,
              synchroStatus
            ).then((response) => {
              resolve(response);
            });
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static deleteCacheObject(
    objectKey: string,
    objectId: number,
    offline: boolean,
    recursive: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      LocalStorageService.deleteLocalObject(
        objectKey,
        objectId,
        offline,
        recursive
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
}

class LocalStorageService {
  // Construction of a LocalStorageKey
  // key.split("__")[0] = Object type e.g. 'diagnostics', 'results', 'exploitations' ...
  // key.split("__")[1] = Object id
  // key.split("__")[2] = Object status (for syncro) 'ok', 'toAdd', 'toUpdate' ou 'toDelete'
  // key.split("__")[3] = Object parent id
  // key example : 'results__24__ok__4' is a result with id 24, no synchronsing needs and attached to diagnostic id 4

  public static fetchLocalObjects(objectKey: string): Promise<Array<any>> {
    return new Promise((resolve) => {
      const cacheObjectsKeys = Object.getOwnPropertyNames(localStorage);
      const wantedObjectsKeys = cacheObjectsKeys.filter((key) => {
        const split = key.split("__");
        return split[0] === objectKey && split[2] !== "toDelete";
      });
      const resultArray = <Array<any>>[];
      for (const key of wantedObjectsKeys) {
        const objectId = parseInt(key.split("__")[1]);
        this.fetchLocalObject(objectKey, objectId).then((response) => {
          resultArray.push(response);
        });
      }
      resolve(resultArray);
    });
  }

  public static fetchLocalObject(
    objectKey: string,
    objectId: number
  ): Promise<any> {
    return new Promise((resolve) => {
      const cacheObjectsKeys = Object.getOwnPropertyNames(localStorage);
      const wantedObjectsKey = cacheObjectsKeys.find((key) => {
        const split = key.split("__");
        let bool = split[0] === objectKey;
        bool = bool && split[1] == objectId.toString();
        return bool;
      });

      if (wantedObjectsKey) {
        const objectLS = localStorage.getItem(wantedObjectsKey);
        if (objectLS) {
          const objectValue = JSON.parse(objectLS);
          const relObjs = offlineObjectsRelatedName[objectKey];
          if (relObjs && relObjs.length > 0) {
            for (const relObj of relObjs) {
              objectValue[relObj] = <Array<any>>[];
              const wantedChildObjectsKeys = cacheObjectsKeys.filter((obj) => {
                const split = obj.split("__");
                return (
                  split[0] === relObj && split[3] === objectValue.id.toString()
                );
              });
              for (const key of wantedChildObjectsKeys) {
                this.fetchLocalObject(
                  relObj,
                  parseInt(key.split("__")[1])
                ).then((response) => {
                  objectValue[relObj].push(response);
                });
              }
            }
            resolve(objectValue);
          } else {
            resolve(objectValue);
          }
        }
      } else {
        resolve(null);
      }
    });
  }

  public static fetchLocalUnsyncedObject(syncType: string) {
    const allKeys = Object.keys(localStorage);
    const unsyncedObjects = {} as any;
    //seperate object into ordered sync categories (diagnostics before results)
    for (const key of Object.keys(offlineObjectsRelatedName)) {
      unsyncedObjects[key] = [] as Array<any>;
      for (const key2 of offlineObjectsRelatedName[key]) {
        unsyncedObjects[key2] = [] as Array<any>;
      }
    }
    for (const localKey of allKeys) {
      const synchroStatus = localKey.split("__")[2];
      const objStr = localStorage.getItem(localKey);
      if (synchroStatus === syncType && objStr) {
        const objectKey = localKey.split("__")[0];
        unsyncedObjects[objectKey].push({
          key: objectKey,
          objectValue: JSON.parse(objStr),
          synchroStatus: synchroStatus,
          id: localKey.split("__")[1],
        });
      }
    }
    return unsyncedObjects;
  }

  //decision table in MindMapActionsOffline.pptx
  public static addLocalObject(
    objectKey: string,
    objectValue: any,
    offline: boolean,
    objectParentId: number | null = null,
    recursive: boolean
  ): Promise<OfflineObjectType> {
    return new Promise<OfflineObjectType>((resolve) => {
      const oldLocalStorageKeys = Object.keys(localStorage).filter((key) =>
        key.includes(objectKey + "__" + objectValue.id + "__")
      );

      if (oldLocalStorageKeys.length > 1) {
        //Error: several object with same id and different synchro status: delete all
        for (const key of oldLocalStorageKeys) {
          localStorage.removeItem(key);
        }
      }

      //Ok: zero or one object with same id
      const oldSynchroStatus: string = oldLocalStorageKeys[0]
        ? oldLocalStorageKeys[0].split("__")[2]
        : "";

      //Remove existing object if needed
      if (
        oldLocalStorageKeys.length === 1 &&
        (offline ||
          (!offline &&
            (oldSynchroStatus === "ok" || oldSynchroStatus === "toAdd")))
      ) {
        localStorage.removeItem(oldLocalStorageKeys[0]);
      }

      //Add object if needed
      if (
        !(
          oldLocalStorageKeys.length === 1 &&
          !offline &&
          (oldSynchroStatus === "toDelete" || oldSynchroStatus === "toUpdate")
        )
      ) {
        //Define new synchroStatus
        const newSynchroStatus = offline ? "toAdd" : "ok";

        //Add
        let localStorageKey =
          objectKey + "__" + objectValue.id + "__" + newSynchroStatus;
        localStorageKey +=
          objectParentId !== null ? "__" + objectParentId.toString() : "";

        const relObjs = offlineObjectsRelatedName[objectKey];
        if (relObjs && relObjs.length > 0 && recursive) {
          const copyObjectValue = deepCopy(objectValue);

          for (const childObjectKey of relObjs) {
            if (objectValue[childObjectKey]) {
              delete copyObjectValue[childObjectKey];
              for (const childObjectValue of objectValue[childObjectKey]) {
                LocalStorageService.addLocalObject(
                  childObjectKey,
                  childObjectValue,
                  false,
                  objectValue.id,
                  recursive
                );
              }
            }
          }
          localStorage.setItem(
            localStorageKey,
            JSON.stringify(copyObjectValue)
          );
        } else {
          localStorage.setItem(localStorageKey, JSON.stringify(objectValue));
        }
      }
      resolve(objectValue);
    });
  }

  public static updateLocalObject(
    objectKey: string,
    objectValue: any,
    offline: boolean,
    synchroStatus = "ok"
  ) {
    return new Promise<OfflineObjectType>((resolve, reject) => {
      const oldLocalStorageKeys = Object.keys(localStorage).filter((key) =>
        key.includes(objectKey + "__" + objectValue.id + "__")
      );

      if (oldLocalStorageKeys.length > 1) {
        //Error: several object with same id and different synchro status: delete all
        for (const key of oldLocalStorageKeys) {
          localStorage.removeItem(key);
        }
      }

      if (oldLocalStorageKeys.length === 0) {
        reject("Object not found: " + objectKey + " " + objectValue.id);
      }

      if (oldLocalStorageKeys.length === 1) {
        let objectRefId = null;
        if (offlineObjectsFK[objectKey]) {
          objectRefId = objectValue[offlineObjectsFK[objectKey]];
        }
        let localStorageKey = objectKey + "__" + objectValue.id;

        const previousStatus = oldLocalStorageKeys[0].split("__")[2];
        const newStatus =
          previousStatus === "toAdd" && offline
            ? previousStatus
            : synchroStatus;
        localStorage.removeItem(oldLocalStorageKeys[0]);
        localStorageKey += "__" + newStatus;
        if (objectRefId != null) {
          localStorageKey += "__" + objectRefId;
        }

        localStorage.setItem(localStorageKey, JSON.stringify(objectValue));
        resolve(objectValue);
      }
    });
  }

  //decision table in MindMapActionsOffline.pptx
  public static deleteLocalObject(
    objectKey: string,
    objectId: number,
    offline: boolean,
    recursive: boolean
  ) {
    return new Promise<any>((resolve, reject) => {
      const oldLocalStorageKeys = Object.keys(localStorage).filter((key) =>
        key.includes(objectKey + "__" + objectId + "__")
      );

      if (oldLocalStorageKeys.length > 1) {
        //Error: several object with same id and different synchro status: delete all
        for (const key of oldLocalStorageKeys) {
          localStorage.removeItem(key);
        }
      }

      if (oldLocalStorageKeys.length === 0) {
        reject("Object not found: " + objectKey + " " + objectId);
      }

      if (oldLocalStorageKeys.length === 1) {
        const localStorageKey = oldLocalStorageKeys[0];
        const split = localStorageKey.split("__");
        if (offline) {
          const currentSynchroStatus = split[2] ? split[2] : "";

          switch (currentSynchroStatus) {
            case "ok":
            case "toUpdate":
              //ok or toUpdate
              localStorage.removeItem(localStorageKey);
              // eslint-disable-next-line no-case-declarations
              const newLocalStorageKey =
                objectKey +
                "__" +
                objectId +
                "__toDelete" +
                (split[3] ? "__" + split[3] : "");
              localStorage.setItem(
                newLocalStorageKey,
                JSON.stringify({
                  id: objectId,
                })
              );
              break;
            case "toAdd":
              localStorage.removeItem(localStorageKey);
              break;
            case "toDelete":
              //Error: Trying to delete object with status 'toDelete': do nothing
              break;
            default:
              reject("Unknown status: " + currentSynchroStatus);
          }
        } else {
          //online
          localStorage.removeItem(localStorageKey);
        }

        //find children to delete
        if (recursive) {
          const relObjs = offlineCascadeDeletion[objectKey];
          if (relObjs && relObjs.length > 0) {
            for (const childObjectKey of relObjs) {
              Object.keys(localStorage).map((key) => {
                const split = key.split("__");
                let bool = split[0] === childObjectKey;
                bool =
                  bool && split[3] ? split[3] === objectId.toString() : false;
                if (bool) {
                  this.deleteLocalObject(
                    childObjectKey,
                    Number(split[1]),
                    offline,
                    recursive
                  );
                }
              });
            }
          }
        }
        resolve({ id: objectId });
      }
    });
  }

  public static removeLocalObjects(localStorageKey) {
    for (const key of Object.keys(localStorage).filter((key) =>
      key.includes(localStorageKey)
    )) {
      localStorage.removeItem(key);
    }
  }

  public static getNewLocalId(objectKey) {
    const cacheKeys = Object.getOwnPropertyNames(localStorage);
    const objectsKeys = cacheKeys.filter(
      (obj) => obj.split("__")[0] === objectKey
    );
    const objectIds = objectsKeys.map((item) => Number(item.split("__")[1]));
    if (objectIds.length === 0) {
      return 0;
    }
    return Math.max(...objectIds) + 1;
  }
}

export default CacheService;
