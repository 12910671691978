import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import OfflineService from "@/core/services/OfflineService";
import { notify } from "@/core/helpers/globalJaya";
declare let window: any;
function saveFile(fileEntry, dataObj) {
  return new Promise((resolve, reject) => {
    fileEntry.createWriter(function (writer) {
      writer.onwriteend = function () {
        notify({
          text: "Vidéo téléchargée",
          color: "success",
          duration: 3000,
        });
        resolve(dataObj);
      };
      writer.onerror = function () {
        notify({
          text: "Erreur lors de l'enregistrement de la vidéo",
          color: "error",
          duration: 3000,
        });
        fileEntry.remove();
        reject();
      };
      writer.write(dataObj);
    });
  });
}
export interface downloadingVideo {
  name: string;
  progress: number;
  xhr: XMLHttpRequest;
}
export interface downloadingVideos {
  videos: Array<downloadingVideo>;
}
export interface videoObject {
  url: string;
  online_url: string;
  description: string;
}
export interface moduleVideo {
  id: number;
  module_name: number;
  video: videoObject;
  order: number;
}
export interface moduleVideos {
  module_videos: Array<moduleVideo>;
}
@Module
export default class VideosModule
  extends VuexModule
  implements downloadingVideos, moduleVideos
{
  videos = [] as Array<downloadingVideo>;
  module_videos = [] as Array<moduleVideo>;
  get currentDlVideos(): Array<downloadingVideo> {
    return this.videos;
  }

  get getModuleVideos(): Array<moduleVideo> {
    return this.module_videos;
  }
  @Mutation
  [Mutations.APPEND_VIDEO](payload) {
    const videoIndex = this.videos.findIndex((e) => {
      return e.name === payload.name;
    });
    if (videoIndex === -1) {
      this.videos.push(payload);
    }
  }
  @Mutation
  [Mutations.UPDATE_VIDEO_PROGRESS](payload) {
    const videoIndex = this.videos.findIndex((e) => {
      return e.name === payload.name;
    });
    if (videoIndex > -1) {
      const video = this.videos[videoIndex];
      video.progress = payload.progress;
    }
  }
  @Mutation
  [Mutations.REMOVE_VIDEO](payload) {
    const videoIndex = this.videos.findIndex((e) => {
      return e.name === payload.name;
    });
    if (videoIndex > -1) {
      // remove item
      this.videos.splice(videoIndex, 1);
    }
  }

  @Mutation
  [Mutations.SET_MODULE_VIDEOS](payload) {
    this.module_videos = payload;
  }

  @Action
  [Actions.DOWNLOAD_VIDEO](payload) {
    const context = this.context;
    return new Promise((resolve, reject) => {
      const videoName = payload.url.split("?token")[0].split("/").slice(-1)[0];
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        process.env.VUE_APP_API_URL.split("/api")[0] + payload.url,
        true
      );
      const headers = ApiService.getApiHeaders();
      xhr.withCredentials = true;
      for (const head in headers) {
        // eslint-disable-next-line no-prototype-builtins
        if (headers.hasOwnProperty(head) && headers[head] !== null) {
          xhr.setRequestHeader(head, headers[head]);
        }
      }
      context.commit(Mutations.APPEND_VIDEO, {
        name: videoName,
        progress: 0,
        xhr: xhr,
      });
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status < 200 || xhr.status >= 300) {
          context.commit(Mutations.REMOVE_VIDEO, { name: videoName });
          const err_msg = "Impossible de télécharger la vidéo";
          notify({
            text: err_msg,
            color: "error",
          });
        } else {
          context
            .dispatch(Actions.SAVE_VIDEO, {
              name: videoName,
              data: this.response,
            })
            .then(() => {
              resolve(this.response);
            });
        }
      };
      xhr.onprogress = function (e) {
        context.commit(Mutations.UPDATE_VIDEO_PROGRESS, {
          name: videoName,
          progress: (100 * e.loaded) / e.total,
        });
      };
      xhr.onerror = function (e) {
        context.commit(Mutations.REMOVE_VIDEO, { name: videoName });
        reject(e);
      };
      xhr.onabort = function () {
        notify({
          text: "Téléchargement interrompu",
          color: "warning",
          duration: 3000,
        });
        context.commit(Mutations.REMOVE_VIDEO, { name: videoName });
      };
      xhr.send();
    });
  }
  @Action
  [Actions.STOP_DOWNLOAD_VIDEO](payload) {
    const videoIndex = this.videos.findIndex((e) => {
      return e.name === payload.name;
    });
    if (videoIndex > -1) {
      this.videos[videoIndex].xhr.abort();
    }
  }
  //TODO delete video file
  @Action
  [Actions.SAVE_VIDEO](payload) {
    return new Promise((resolve, reject) => {
      const context = this.context;
      if (window.cordova) {
        window.resolveLocalFileSystemURL(
          window.cordova.file.dataDirectory,
          function (directoryEntry) {
            // retrieve directory and create if needed
            directoryEntry.getDirectory(
              "AgribestVideos",
              { create: true },
              function (logDirectoryEntry) {
                // create file
                logDirectoryEntry.getFile(
                  payload.name,
                  { create: true },
                  function () {
                    // write data to the file
                    window.resolveLocalFileSystemURL(
                      window.cordova.file.dataDirectory +
                        "/AgribestVideos/" +
                        payload.name,
                      function (fileEntry) {
                        saveFile(fileEntry, payload.data).then((response) => {
                          resolve(response);
                        });
                      },
                      function (error) {
                        context.commit(Mutations.REMOVE_VIDEO, payload);
                        reject(error);
                      }
                    );
                  }
                );
              }
            );
          },
          function (error) {
            context.commit(Mutations.REMOVE_VIDEO, payload);
            reject(error);
          }
        );
      }
    });
  }

  @Action
  [Actions.FETCH_MODULE_VIDEOS]() {
    return new Promise((resolve, reject) => {
      OfflineService.fetchObject("module_videos")
        .then((response) => {
          this.context.commit(Mutations.SET_MODULE_VIDEOS, response);
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer lle module vidéo AgriBEST";
          notify({
            text: err_msg,
            color: "error",
          });
        });
    });
  }
}
