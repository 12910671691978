import { createToaster } from "@meforma/vue-toaster";

export const parseDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
};

export const notify = (props) => {
  const toaster = createToaster({
    position: props.position ? props.position : "bottom-right",
    type: props.color,
    duration: props.duration ? props.duration : 3000,
    maxToasts: 20,
  });
  toaster.show(props.text);
};

export const correctResultColor = (result) => {
  if (result <= 16.6) {
    return "#F64E60";
  } else if (result <= 33.3) {
    return "#FFA800";
  } else if (result <= 49.9) {
    return "#D9C903";
  } else if (result <= 66.6) {
    return "#ADDA1C";
  } else if (result <= 83.3) {
    return "#82B624";
  }
  return "#73A120";
};

export const deepCopy = (obj) => {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const areObjectsEqual = (a, b) => {
  if (a === b) {
    return true;
  }
  if (a instanceof Object && b instanceof Object) {
    if (a.constructor !== b.constructor) {
      return false;
    }
    for (const key in a) {
      // eslint-disable-next-line no-prototype-builtins
      if (a.hasOwnProperty(key)) {
        // eslint-disable-next-line no-prototype-builtins
        if (!b.hasOwnProperty(key) || !areObjectsEqual(a[key], b[key])) {
          return false;
        }
      }
    }
    for (const key in b) {
      // eslint-disable-next-line no-prototype-builtins
      if (b.hasOwnProperty(key) && !a.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const openLinkAgriBest = (url) => {
  if (process.env.VUE_APP_USE_TOKEN) {
    // Force using external browser
    (window as any).cordova.InAppBrowser.open(url, "_system");
  } else {
    window.open(url, "_blank");
  }
};

export const getImageUrl = (url) => {
  return process.env.VUE_APP_API_URL + "/.." + url;
};
