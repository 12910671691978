import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { notify } from "@/core/helpers/globalJaya";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export interface GdprLastDocNotSigned {
  id: number;
  doc_type: number;
  version: string;
  date_created: string;
  title: string;
}

export interface GdprLastDoc extends GdprLastDocNotSigned {
  content: string;
}

export interface GdprDocType {
  choice: number;
  name: string;
}

export interface GdprRecipientType {
  choice: number;
  name: string;
  description: string;
}

export interface GdprPurpose {
  id: number;
  id_chart: number;
  description: string;
  content_type: string;
  recipient_type: number;
  rejectable: boolean;
}

export interface GdprPurposeStateGlobal {
  id: number;
  purpose: GdprPurpose;
  state: boolean;
}

@Module
export default class GdprModule extends VuexModule {
  gdprLastDocsNotSigned = [] as Array<GdprLastDocNotSigned>;
  gdprLastDocs = [] as Array<GdprLastDoc>;
  gdprDocTypes = [
    {
      choice: 1,
      name: "legal-notice",
    },
    {
      choice: 2,
      name: "general-conditions",
    },
    {
      choice: 3,
      name: "gdpr-charter",
    },
    {
      choice: 4,
      name: "cookie-policy",
    },
    {
      choice: 5,
      name: "prescriber-charter",
    },
  ] as Array<GdprDocType>;
  gdprRecipientTypes = [
    {
      choice: 2,
      name: "autres utilisateurs d'AgriBEST (agriculteur.rice.s)",
      description:
        "Intégration de vos données pour des statistiques non-identifiantes utiles pour les utilisateurs d’AgriBEST (comparer vos résultats à des références moyennes par Otex, Label et département)",
    },
    {
      choice: 1,
      name: "co-fondateurs d'AgriBEST",
      description:
        "Intégration de vos données pour des statistiques non-identifiantes utiles pour communiquer sur l’utilisation de l’outil et les engagements des utilisateurs d’AgriBEST",
    },
  ] as Array<GdprRecipientType>;
  gdprPurposeStateGlobal = [] as Array<GdprPurposeStateGlobal>;

  get getGdprDocTypes() {
    return this.gdprDocTypes;
  }

  get getGdprRecipientTypes() {
    return this.gdprRecipientTypes;
  }

  get getGdprLastDocsNotSigned() {
    return this.gdprLastDocsNotSigned;
  }

  get getGdprLastDocs() {
    return this.gdprLastDocs;
  }

  get getGdprPurposeStateGlobal() {
    return this.gdprPurposeStateGlobal;
  }

  @Mutation
  [Mutations.SET_GDPR_LAST_DOCS](payload) {
    payload.forEach((payloadEl) => {
      this.gdprLastDocs = this.gdprLastDocs.filter(
        (gdprLastDoc) => gdprLastDoc.doc_type !== payloadEl.doc_type
      );
      this.gdprLastDocs.push(payloadEl);
    });
  }

  @Mutation
  [Mutations.SET_GDPR_LAST_DOCS_NOT_SIGNED](payload) {
    this.gdprLastDocsNotSigned = payload;
  }

  @Mutation
  [Mutations.SET_GDPR_PURPOSE_STATE_GLOBAL](payload) {
    this.gdprPurposeStateGlobal = payload;
  }

  @Action({ rawError: true })
  [Actions.FETCH_GDPR_LAST_DOCS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query("gdpr_last_docs/", { params: payload })
        .then((response) => {
          this.context.commit(Mutations.SET_GDPR_LAST_DOCS, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de récupérer les dernières versions des documents RGPD";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.CREATE_GDPR_SIGNATURE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("gdpr_signature/", payload)
        .then((response) => {
          const name = this.gdprLastDocs.find(
            (doc) => doc.id === payload.gdpr_doc
          )?.title;
          notify({
            text:
              "La signature du document '" +
              name +
              "' a bien été prise en compte",
            color: "success",
            duration: 3000,
          });
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de signer le document RGPD";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.FETCH_GDPR_LAST_DOCS_NOT_SIGNED]() {
    return new Promise((resolve, reject) => {
      return ApiService.get("gdpr_last_docs_not_signed")
        .then((response) => {
          this.context.commit(
            Mutations.SET_GDPR_LAST_DOCS_NOT_SIGNED,
            response.data
          );
          resolve(response);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de récupérer les documents RGPD non signé";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.FETCH_GDPR_PURPOSE_STATE_GLOBAL]() {
    return new Promise((resolve, reject) => {
      ApiService.get("gdpr_purpose_state_global")
        .then((response) => {
          this.context.commit(
            Mutations.SET_GDPR_PURPOSE_STATE_GLOBAL,
            response.data
          );
          resolve(response.data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de récupérer les choix de partage de données";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_GDPR_PURPOSE_STATE_GLOBAL](payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch("gdpr_purpose_state_global/" + payload.id + "/", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de mettre à jour les choix de partage de données";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }
}
