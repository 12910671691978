
import { defineComponent, nextTick, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { notify } from "@/core/helpers/globalJaya";
import { useRouter } from "vue-router";
import OfflineService from "@/core/services/OfflineService";
import { version } from "@/../package.json";

declare let window: any;
export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();
    if (process.env.VUE_APP_USE_TOKEN) {
      window.handleOpenURL = (url) => {
        // This regex remove the scheme when it is necessary
        router.push("/" + url.replace(/^[^:]*:\/*/, ""));
      };
    }
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      if (window.cordova) {
        store
          .dispatch(Actions.FETCH_MINIMUM_VERSION)
          .then((minimum_version) => {
            if (version < minimum_version) {
              router.push({ name: "update-required" });
            }
          });
      }

      const manageFCM = function () {
        store.dispatch(Actions.REQUEST_FIREBASE_TOKEN, {});
      };
      if (process.env.VUE_APP_USE_TOKEN) {
        document.addEventListener(
          "deviceready",
          () => {
            manageFCM();
          },
          false
        );
        document.addEventListener(
          "reload",
          () => {
            store.dispatch(Actions.FETCH_LATEST_NOTIFICATION, {});
          },
          false
        );
      }

      const onOffline = () => {
        notify({
          text: "Connexion au réseau interrompue, AgriBEST fonctionne maintenant en mode hors-ligne.",
          color: "warning",
          duration: 3000,
        });
        store.commit(Mutations.SET_ONLINE, false);
      };

      const onOnline = () => {
        OfflineService.syncCache();
        notify({
          text: "Connexion au réseau rétablie.",
          color: "success",
          duration: 2000,
        });
        store.commit(Mutations.SET_ONLINE, true);
      };

      document.addEventListener(
        "deviceready",
        function () {
          if (process.env.VUE_APP_USE_TOKEN) {
            document.addEventListener("online", onOnline, false);
            document.addEventListener("offline", onOffline, false);
          } else {
            window.addEventListener("offline", onOffline);
            window.addEventListener("online", onOnline);
          }
        },
        false
      );

      onUnmounted(() => {
        if (process.env.VUE_APP_USE_TOKEN) {
          document.removeEventListener("online", onOnline);
          document.removeEventListener("offline", onOffline);
        } else {
          window.removeEventListener("online", onOnline);
          window.removeEventListener("offline", onOffline);
        }
      });

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
