import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { notify } from "@/core/helpers/globalJaya";
import ApiService from "@/core/services/ApiService";

export interface Prescriber {
  id: number | null;
  name: string | null;
  admin: string | null;
  image_url?: string | null;
  image: number | null;
  communication_website: boolean | null;
  communication_external: boolean | null;
}

export interface PrescriberProject {
  id: number | null;
  prescriber: number | null;
  name: string | null;
  details: string | null;
  gdpr_purposes: Array<number> | null;
  users: Array<number> | null;
  prescriber_charter: number | null;
  state: number | null;
  project_purposes: Array<number> | null;
  partners: string;
  diagnostics_count?: number;
  users_count?: number;
  region: Array<number | null> | null;
  additional_indicators?: Array<SelectedIndicator>;
}

export interface UserProject {
  name: string;
  details: string;
  prescriber_charter: number | null;
  gdpr_purposes: string[];
  project_purposes: string[];
  partners: string;
  id: number | null;
  prescriber: Prescriber | null;
  linkId?: number;
}

export interface PrescriberProjectState {
  state: number;
  name: string;
}

export interface PrescriberProjectPurposes {
  number: number;
  description: string;
}

export interface Indicator {
  id: number;
  name: string;
  description: string;
  units: string;
  data_type: string;
  label?: string;
}

export interface SelectedIndicator {
  id: number;
  project: number;
  indicator: number;
  required: boolean;
  indicator_details?: Indicator;
}

@Module
export default class PrescriberModule extends VuexModule {
  prescriberProjectStates = [
    {
      state: 1,
      name: "Created",
    },
    {
      state: 2,
      name: "In validation",
    },
    {
      state: 3,
      name: "Validated",
    },
  ] as Array<PrescriberProjectState>;
  prescriberProjectPurposes = [
    {
      number: 1,
      description: "Sensibliser les producteurs-rices",
    },
    {
      number: 2,
      description: "Animer un-des groupes de progrès",
    },
    {
      number: 3,
      description:
        "Formation des producteurs.rices (individuelle ou collective)",
    },
    {
      number: 4,
      description: "Formation de conseillers",
    },
    {
      number: 5,
      description: "Formation de futurs actifs agricoles",
    },
    {
      number: 6,
      description:
        "Mettre en place un plan de progrès/ un projet d'amélioration de pratiques",
    },
    {
      number: 7,
      description:
        "Préserver un patrimoine/ une ressource spécifique (milieux, espèces, génétiques)",
    },
    {
      number: 8,
      description:
        "Identifier les pratiques et/ou les services écosystémiques pour faciliter leur valorisation (valorisation marché, démarche de PSE privés ou publics)",
    },
    {
      number: 9,
      description: "Faciliter le dialogue et le croisement des connaissances",
    },
    {
      number: 10,
      description: "Autres (Préciser)",
    },
  ] as Array<PrescriberProjectPurposes>;

  prescribers = [] as Array<Prescriber>;
  prescriberProjects = [] as Array<PrescriberProject>;
  userProjects = [] as Array<UserProject>;
  indicators = [] as Array<Indicator>;
  exploitationsToUpdate = [] as Array<number>;

  get getPrescribers() {
    return this.prescribers;
  }

  get getPrescriberProjects() {
    return this.prescriberProjects;
  }

  get getPrescriberProjectStates() {
    return this.prescriberProjectStates;
  }

  get getIndicators() {
    return this.indicators;
  }

  get getPrescriberProjectPurposes() {
    return this.prescriberProjectPurposes;
  }
  get getExploitationsToUpdate() {
    return this.exploitationsToUpdate;
  }
  get getUserProjects() {
    return this.userProjects;
  }

  @Mutation
  [Mutations.SET_PRESCRIBERS](prescribers) {
    if (!Array.isArray(prescribers)) {
      prescribers = [prescribers];
    }
    const prescribersMap = new Map(this.prescribers.map((p) => [p.id, p]));
    prescribers.forEach((p1) => {
      prescribersMap.set(p1.id, p1);
    });
    this.prescribers = Array.from(prescribersMap.values());
  }

  @Mutation
  [Mutations.REMOVE_PRESCRIBER](id) {
    this.prescribers = this.prescribers.filter((e) => e.id !== id);
    this.prescriberProjects = this.prescriberProjects.filter(
      (e) => e.prescriber !== id
    );
  }

  @Mutation
  [Mutations.SET_PRESCRIBER_PROJECTS](prescriberProjects) {
    if (!Array.isArray(prescriberProjects)) {
      prescriberProjects = [prescriberProjects];
    }
    const prescriberProjectsMap = new Map(
      this.prescriberProjects.map((p) => [p.id, p])
    );
    prescriberProjects.forEach((p1) => {
      prescriberProjectsMap.set(p1.id, p1);
    });
    this.prescriberProjects = Array.from(prescriberProjectsMap.values());
  }
  @Action
  [Actions.ADD_PRESCRIBER_FOUNDERS](payload) {
    return new Promise((resolve, reject) => {
      payload.admin_email = payload.admin;
      delete payload.admin;
      delete payload.image_url;
      ApiService.post("prescribers_founders/", payload)
        .then((response) => {
          this.context.commit(Mutations.SET_PRESCRIBERS, response.data);
          notify({
            text: "Le prescripteur a bien été ajouté",
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible d'ajouter le prescripteur";
          if (error.response && error.response.data) {
            if (error.response.data.detail === "User not found") {
              notify({
                text: "Cette adresse mail n'existe pas",
                color: "error",
              });
            } else if (
              error.response.data.name.includes(
                "prescriber with this name already exists."
              )
            ) {
              notify({
                text: "Cette adresse mail est déjà rattachée au compte administrateur d'un autre prescripteur",
                color: "error",
              });
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_PRESCRIBER_FOUNDERS](payload) {
    return new Promise((resolve, reject) => {
      payload.admin_email = payload.admin;
      delete payload.admin;
      ApiService.patch(
        "prescribers_founders/" + payload.id.toString() + "/",
        payload
      )
        .then((response) => {
          this.context.commit(Mutations.SET_PRESCRIBERS, response.data);
          notify({
            text: "Le prescripteur a bien été mis a jour",
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de mettre à jour le prescripteur";
          if (error.response && error.response.data) {
            if (error.response.data.detail === "User not found") {
              notify({
                text: "Cette adresse mail n'existe pas",
                color: "error",
              });
            } else if (
              error.response.data.detail ===
              "User is already admin of an other prescriber"
            ) {
              notify({
                text: "Cette adresse mail est déjà rattachée au compte administrateur d'un autre prescripteur",
                color: "error",
              });
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.FETCH_PRESCRIBER_FOUNDERS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("prescribers_founders")
        .then((response) => {
          this.context.commit(Mutations.SET_PRESCRIBERS, response.data);
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la récupration des prescripteurs",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_PRESCRIBER_FOUNDERS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("prescribers_founders/" + payload.id.toString() + "/")
        .then((response) => {
          this.context.commit(Mutations.REMOVE_PRESCRIBER, payload.id);
          notify({
            text: "Le prescripteur a bien été supprimé, ainsi que tous les projets associés",
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de supprimer le prescripteur";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.FETCH_PRESCRIBER_PROJECT_FOUNDERS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("prescribers_projects_founders")
        .then((response) => {
          this.context.commit(Mutations.SET_PRESCRIBER_PROJECTS, response.data);
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la récupration des projets des prescripteurs",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_PRESCRIBER_PROJECT_FOUNDERS](id) {
    return new Promise((resolve, reject) => {
      ApiService.get("prescribers_projects_founders/" + id.toString())
        .then((response) => {
          resolve(response.data as PrescriberProject);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la récupération du projet",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_PRESCRIBER_PROJECT_FOUNDERS](prescriberProject) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        "prescribers_projects_founders/" + prescriberProject.id.toString() + "/"
      )
        .then((response) => {
          notify({
            text: "Le projet a bien été supprimé",
            color: "error",
          });
          this.context.commit(
            Mutations.REMOVE_PRESCRIBER_PROJECT,
            prescriberProject.id
          );
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la suppression du projet",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Mutation
  [Mutations.APPEND_PRESCRIBER_PROJECT](payload) {
    this.prescriberProjects.push(payload);
  }

  @Mutation
  [Mutations.REMOVE_PRESCRIBER_PROJECT](id) {
    this.prescriberProjects = this.prescriberProjects.filter((project) => {
      return project.id !== id;
    });
  }

  @Mutation
  [Mutations.REMOVE_USER_PROJECT](id) {
    this.userProjects = this.userProjects.filter((project) => {
      return project.linkId !== id;
    });
  }

  @Action
  [Actions.FETCH_PRESCRIBER_PROJECTS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("prescriber_projects")
        .then((response) => {
          this.context.commit(Mutations.SET_PRESCRIBER_PROJECTS, response.data);
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la récupration des projets",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_PRESCRIBER_PROJECT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("prescriber_projects/", payload)
        .then((response) => {
          this.context.commit(
            Mutations.APPEND_PRESCRIBER_PROJECT,
            response.data
          );
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la création du projet",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_PRESCRIBER_PROJECT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        "prescriber_projects/" + payload.id.toString() + "/",
        payload
      )
        .then((response) => {
          if (payload.state && payload.state === 4) {
            this.context.commit(
              Mutations.REMOVE_PRESCRIBER_PROJECT,
              payload.id
            );
          }
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la mise à jour du projet",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_PRESCRIBER_PROJECT](id) {
    return new Promise((resolve, reject) => {
      ApiService.delete("prescriber_projects/" + id.toString() + "/")
        .then((response) => {
          this.context.commit(Mutations.REMOVE_PRESCRIBER_PROJECT, id);
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la mise à jour du projet",
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Action
  [Actions.GET_PRESCRIBER_PROJECT](id) {
    return new Promise((resolve, reject) => {
      ApiService.get("prescriber_projects/" + id.toString())
        .then((response) => {
          resolve(response.data as PrescriberProject);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la récupération du projet",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_PRESCRIBER_PROJECT_FOUNDER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        "prescribers_projects_founders/" + payload.id.toString() + "/",
        payload
      )
        .then((response) => {
          this.context.commit(Mutations.SET_PRESCRIBER_PROJECTS, response.data);
          notify({
            text: "Le projet du prescripteur a bien été mis a jour",
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Une erreur est survenue à la mise à jour du projet",
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_PROJECT_BY_UUID](uuid) {
    return new Promise((resolve, reject) => {
      ApiService.get("prescribers_project_infos/" + uuid).then((response) => {
        resolve(response.data as UserProject);
      });
    });
  }

  @Action
  [Actions.ADD_USER_PROJECT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user_projects/", payload)
        .then((response) => {
          resolve(response.data as UserProject);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            if (
              error.response.data &&
              error.response.data.detail ===
                "Only one link between a project and an exploitation"
            ) {
              notify({
                text: "Exploitation déjà liée au projet",
                color: "error",
                duration: 5000,
              });
            }
          }
          notify({
            text: "Erreur lors de l'inscription au projet",
            color: "error",
            duration: 5000,
          });
          reject();
        });
    });
  }
  @Action
  [Actions.FETCH_USER_PROJECTS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("user_projects")
        .then((response) => {
          this.context.commit(
            Mutations.SET_USER_PROJECTS,
            response.data.map((e) => {
              return { ...e.project_infos, linkId: e.id } as UserProject;
            })
          );
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Erreur lors de la récupération des projets",
            time: 3000,
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Mutation
  [Mutations.SET_USER_PROJECTS](payload: Array<UserProject>) {
    this.userProjects = payload;
  }

  @Action
  [Actions.DELETE_USER_PROJECT](id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`user_projects/${id}/`)
        .then((response) => {
          this.context.commit(Mutations.REMOVE_USER_PROJECT, id);
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Erreur à la suppression d'un projet",
            color: "error",
            time: 3000,
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.SEND_MAIL_DB_DOWNLOAD_CSV_PRESCRIBERS](project) {
    return new Promise((resolve, reject) => {
      ApiService.post("/mail_db_as_csv_prescribers/", {
        params: { project_uuid: project.uuid },
      })
        .then(({ data }) => {
          notify({
            color: "success",
            text:
              "Le mail pour télécharger les données du projet '" +
              project.name +
              "' a bien été envoyé.",
          });
          resolve(data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible d'envoyer le mail pour télécharger les données du projet AgriBEST. ";
          notify({
            text: err_msg + error.response.data.detail,
            color: "error",
            duration: 10000,
          });
          reject(error);
        });
    });
  }
  @Mutation
  [Mutations.SET_INDICATORS](payload: Array<Indicator>) {
    this.indicators = payload;
  }
  @Action
  [Actions.FETCH_INDICATORS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("/indicators")
        .then((response) => {
          this.context.commit(Mutations.SET_INDICATORS, response.data);
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Mutation
  [Mutations.SET_EXPLOITATIONS_TO_UPDATE](exploitationIds) {
    this.exploitationsToUpdate = exploitationIds;
  }

  @Action({ rawError: true })
  [Actions.CHECK_INDICATORS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("/check_indicators")
        .then((response) => {
          this.context.commit(
            Mutations.SET_EXPLOITATIONS_TO_UPDATE,
            response.data[0].exploitations
          );
          resolve(response.data[0]);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  @Action
  [Actions.ADD_SELECTED_INDICATOR](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/selected_indicators/", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  @Action
  [Actions.DESTROY_SELECTED_INDICATOR](id: number) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/selected_indicators/${id}`)
        .then(() => {
          resolve({});
        })
        .catch(() => reject({}));
    });
  }
}
